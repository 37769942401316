@import 'app.scss';
/* stylelint-disable selector-class-pattern */

/**
 * Loader component
 */

/**
 * ======================================
 * Block
 * ======================================
 */
.container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  flex: 100% 1 1;
  z-index: 1;
  margin: calc(-1 * #{spacing(2)});
  border-radius: var(--border-radius-base);
  background-color: rgb(var(--color-gray-900-rgb) 0.05);
  width: 100%;
  height: calc(100% + #{spacing(4)});
  min-height: 150px;
}

/**
 * ======================================
 * Elements
 * ======================================
 */

.spinner {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
}

/**
 * ======================================
 * Modifiers
 * ======================================
 */
.absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.container.verticalPadding {
  padding-top: var(--section-vertical-spacing);
  padding-bottom: var(--section-vertical-spacing);
}
