/* stylelint-disable selector-class-pattern */

.u-menu-link {
  @include menu-link;
}

.u-menu-link--active {
  text-decoration: underline;
  color: var(--color-red-500);
}
