@mixin menu-link {
  display: flex;
  gap: spacing(2);
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  line-height: 2;
  color: var(--color-fg-body);
  font-size: 1.375rem;
  font-weight: 400;
  text-underline-offset: 0.15em;

  &:hover,
  &:focus-visible {
    outline: none;
    text-decoration: underline;
    color: var(--color-red-500);

    @include from-breakpoint($menu-breakpoint) {
      text-decoration: none;

      svg {
        color: var(--color-black-500);
      }
    }
  }

  svg {
    color: var(--color-red-500);
  }

  @include from-breakpoint($menu-breakpoint) {
    max-width: var(--menu-link-size);
    text-decoration: underline;
    line-height: 2.1;
  }

  @media (pointer: fine) {
    &:focus-visible {
      @include generic-focus;
    }
  }
}
