/* stylelint-disable selector-class-pattern */

/**
 * Grid layout utility
 */

.u-grid {
  --grid-spacing: #{spacing(5)};

  display: grid;
  grid-template-columns: auto;
  grid-gap: var(--grid-spacing);
}

/**
 * Utility classes for grid spacing.
 */

.u-grid--gap-0 {
  --grid-spacing: #{spacing(0)};
}

.u-grid--gap-2 {
  --grid-spacing: #{spacing(2)};
}

.u-grid--gap-3 {
  --grid-spacing: #{spacing(3)};
}

.u-grid--gap-4\@lg {
  @include from-breakpoint(sm) {
    --grid-spacing: #{spacing(4)};
  }
}

.u-grid--gap-6\@sm {
  @include from-breakpoint(sm) {
    --grid-spacing: #{spacing(6)};
  }
}

/**
 * Utility classes for creating responsive grid layouts.
 */
.u-grid--2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.u-grid--2\@sm {
  @include from-breakpoint(sm) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.u-grid--3\@sm {
  @include from-breakpoint(sm) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.u-grid--3\@lg {
  @include from-breakpoint(lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.u-grid--4\@lg {
  @include from-breakpoint(lg) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.u-grid--4\@xl {
  @include from-breakpoint(xl) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
