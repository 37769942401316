@import 'app.scss';
.content {
  a {
    text-decoration: underline;

    &:hover,
    &:focus {
      color: var(--color-red-500);
    }
  }

  /**
  * Remove margin-bottom from last child of this container
  */
  > :last-child {
    margin-bottom: 0;
  }

  table {
    border-collapse: collapse;

    tr {
      &:nth-child(odd) {
        background-color: var(--color-black-100);
      }

      td {
        padding: spacing(4);

        &:first-child {
          border-top-left-radius: var(--border-radius-base);
          border-bottom-left-radius: var(--border-radius-base);
        }

        &:last-child {
          border-top-right-radius: var(--border-radius-base);
          border-bottom-right-radius: var(--border-radius-base);
          text-align: right;
        }
      }
    }
  }
}
