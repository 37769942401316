@import 'app.scss';
.wrapper {
  --horizontal-padding: 1.25rem;
  --icon-size: 1.5rem;
  --chevron-size: 0.75rem;

  display: flex;
  position: relative;
  border-radius: var(--border-radius-base);
  width: 100%;
  max-width: 19rem;

  &.fitContent {
    width: fit-content;
  }

  .lightGray,
  .gray {
    border-color: var(--color-border-base);

    .chevron {
      color: var(--color-black-500);
    }
  }

  .gray {
    background-color: var(--color-black-100);
  }
}

.select {
  @include typography-button;

  appearance: none;
  border: solid 1px var(--color-gray-500);
  border-radius: var(--border-radius-base);
  background-color: inherit;
  cursor: pointer;
  padding: 0.75rem
    calc(var(--horizontal-padding) + var(--chevron-size) + spacing(2)) 0.75rem
    var(--horizontal-padding);
  width: 100%;
  height: 100%;
  min-height: 50px;
  color: var(--color-black-500);

  &::-ms-expand {
    display: none;
  }

  &:hover,
  &:focus-visible {
    border-color: var(--color-black-300);
  }

  &:focus {
    outline: none;
  }

  .shadow & {
    box-shadow: var(--box-shadow-card);
  }

  &.withIcon {
    padding-left: calc(var(--horizontal-padding) + var(--icon-size) + 0.5rem);
  }

  .lightGray & {
    background-color: var(--color-light-gray-500);
  }
}

.icon {
  position: absolute;
  top: 50%;
  left: var(--horizontal-padding);
  transform: translateY(-50%);
  width: var(--icon-size);
  height: var(--icon-size);
  color: var(--color-red-500);
  pointer-events: none;
}

.chevron {
  position: absolute;
  top: 50%;
  right: var(--horizontal-padding);
  transform: translateY(-50%);
  width: var(--chevron-size);
  height: var(--chevron-size);
  color: var(--color-red-500);
  pointer-events: none;
}
