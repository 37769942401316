@import 'app.scss';
.list {
  display: grid;
  gap: spacing(4);
  padding-bottom: spacing(4);

  @include from-breakpoint(sm) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.shareButton {
  margin-top: spacing(4);
  margin-bottom: spacing(8);

  @include from-breakpoint(md) {
    align-self: flex-start;
    width: max-content;
  }
}
