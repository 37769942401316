@import 'app.scss';
.trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: spacing(4) 0;
  width: 100%;
  color: var(--color-black);

  @include from-breakpoint(md) {
    cursor: default;
    padding: 0;
  }
}

.navigation {
  display: flex;
  flex-direction: column;
  gap: spacing(2);
  margin-bottom: 0;
  padding-bottom: spacing(4);
}

.icon {
  transition: transform var(--transition-base);

  &.iconAnimate {
    transform: rotate(180deg);
  }

  @include from-breakpoint(md) {
    display: none;
  }
}

.navigationWrapper {
  transition: var(--transition-duration-collapse);
  overflow: hidden;
}

.fadeEnter {
  height: 0;
}

.fadeEnterActive {
  height: var(--navigation-height);
}

.fadeExit {
  height: var(--navigation-height);
}

.fadeExitActive {
  height: 0;
}
