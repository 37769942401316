/*  =========================================================================
    Variables
    Global variables, config switches.
    You can NOT import this file other .scss files, instead it is imported in _app.js
    ========================================================================= */
:root,
::marker,
::after,
::before {
  --max-container-width: 81rem;
  --horizontal-container-padding: #{spacing(6)};

  // Brand colors

  // Red
  --color-red-600: #d3002a;
  --color-red-500: #f03;
  --color-red-400: #ff4066;
  --color-red-300: #ff8099;
  --color-red-200: #ffbfcc;
  --color-red-100: #ffe5eb;

  // Black
  --color-black-500: #000;
  --color-black-400: #404040;
  --color-black-300: #808080;
  --color-black-200: #bfbfbf;
  --color-black-100: #f2f2f2;

  // Purple
  --color-purple-700: #681c65;
  --color-purple-500: #93278f;
  --color-purple-400: #ae5dab;
  --color-purple-300: #c993c7;
  --color-purple-200: #e4c9e3;
  --color-purple-100: #faf4f9;

  // Orange
  --color-orange-500: #ff5e22;
  --color-orange-400: #ff8659;
  --color-orange-300: #ffae90;
  --color-orange-200: #ffd7c8;
  --color-orange-100: #fff7f4;

  // Sand
  --color-sand-700: #cf6f2f;
  --color-sand-500: #e6884e;
  --color-sand-400: #eca67a;
  --color-sand-300: #f3c3a6;
  --color-sand-200: #f6d5c1;
  --color-sand-100: #fcf3ed;

  // Gold
  --color-gold-500: #f2b136;
  --color-gold-400: #f5c568;
  --color-gold-300: #f9d89a;
  --color-gold-200: #fcebcd;
  --color-gold-100: #fefbf5;

  // Yellow
  --color-yellow-500: #fdd61e;
  --color-yellow-400: #fde056;
  --color-yellow-300: #feeb8e;
  --color-yellow-200: #fff5c7;
  --color-yellow-100: #fffdf4;

  // Indigo
  --color-indigo-500: #4a2d88;
  --color-indigo-400: #7762a6;
  --color-indigo-300: #a496c3;
  --color-indigo-200: #d2cae1;
  --color-indigo-100: #f6f4f9;

  // Turquoise
  --color-turquoise-700: #136b63;
  --color-turquoise-500: #00b3ad;
  --color-turquoise-400: #40c6c1;
  --color-turquoise-300: #80d9d6;
  --color-turquoise-200: #bfeceb;
  --color-turquoise-100: #f2fbfb;

  // Steel
  --color-steel-500: #007397;
  --color-steel-400: #4096b1;
  --color-steel-300: #80b9cb;
  --color-steel-200: #bfdce5;
  --color-steel-100: #f2f8fa;

  // Royal
  --color-royal-500: #003380;
  --color-royal-400: #4066a0;
  --color-royal-300: #8099bf;
  --color-royal-200: #bfccdf;
  --color-royal-100: #e5ebf2;

  // Green
  --color-green-500: #7fc466;
  --color-green-400: #9fd38c;
  --color-green-300: #bfe1b2;
  --color-green-200: #dff0d9;
  --color-green-100: #f9fcf7;

  // Status colors
  --color-succes-100: hsl(143deg 50% 90%);
  --color-succes-500: hsl(143deg 100% 33%);
  --color-succes-900: hsl(143deg 100% 7%);
  --color-warning-100: hsl(45deg 87% 90%);
  --color-warning-500: hsl(45deg 87% 62%);
  --color-warning-900: hsl(45deg 100% 10%);
  --color-error-100: hsl(0deg 100% 95%);
  --color-error-200: hsl(0deg 100% 77%);
  --color-error-500: hsl(0deg 100% 57%);
  --color-error-700: hsl(0deg 100% 37%);
  --color-error-900: hsl(0deg 100% 10%);

  // Gray colors
  --color-white: #fff;
  --color-gray-100: hsl(0deg 0% 96%); // #F6F6F6
  --color-gray-200: hsl(0deg 0% 86%); // #DBDBDB
  --color-gray-300: hsl(0deg 0% 93%); // #EDEDED
  --color-gray-500: hsl(0deg 0% 86%); // #DCDCDC
  --color-light-gray-500: hsl(0deg 0% 95%); // #F2F2F2
  --color-gray-900: hsl(240deg 1% 29%); // #4A4A4C
  --color-black: #000;
  --color-black-rgb: 0, 0, 0;
  --color-text-base: var(--color-black);
  --color-border-base: rgb(0 0 0 / 0.18);
  --color-border-light: rgb(0 0 0 / 0.1);
  --color-backdrop: rgb(0 0 0 / 0.7);

  // Theme colors
  --color-breda-straalt: #1d3f26;
  --color-breda-straalt-hover: #234b2d;

  // Font settings
  --font-family-base: 'Calibre', sans-serif;

  // Onmount keyframes animations
  --onmount-animation-duration: 600ms;

  // Transitions
  --transition-duration-base: 150ms;
  --transition-duration-menu: 300ms;
  --transition-duration-collapse: 300ms;
  --transition-duration-interaction: 450ms;

  // Google swift bezier
  --transition-timing-base: cubic-bezier(0.55, 0, 0.1, 1);
  --transition-base: var(--transition-duration-base)
    var(--transition-timing-base);

  // Borders
  --border-radius-extra-small: 0.375rem;
  --border-radius-small: 0.5rem;
  --border-radius-base: 0.75rem;
  --border-radius-large: 1.25rem;

  // Shadows
  --box-shadow-base: 0 1.5rem 2rem rgb(0 0 0 / 0.05);
  --box-shadow-alternative: 1rem 1.5rem 7rem 1.5rem rgb(0 0 0 / 0.05);
  --box-shadow-card: 0px 0px 24px 0px rgb(0 0 0 / 0.03),
    0px 1px 6px 0px rgb(0 0 0 / 0.05);

  // defaults to big touch targets
  --target-base-height: 48px;
  --target-small-height: var(--target-base-height);
  --target-tiny-height: var(--target-base-height);

  // Pagepart spacing
  --section-vertical-spacing: #{spacing(8)};

  // Header panel items
  --panel-item-size: 4.5rem; // 72px

  // Hero variables
  --max-hero-content-width: 17rem;
  --large-hero-content-width: max(
    (100% - var(--max-container-width)) / 2 + var(--max-hero-content-width) +
      var(--horizontal-container-padding),
    var(--max-hero-content-width) + 2 * var(--horizontal-container-padding)
  );

  /* Toastify variables */
  --toastify-color-light: var(--color-white) !important;
  --toastify-color-progress-light: #f00 !important;
  --toastify-text-color-light: var(--color-black) !important;
  --toastify-font-family: var(--font-family-base) !important;

  // Input variables
  --input-height: 3.125rem;

  @include from-breakpoint(lg) {
    --section-vertical-spacing: #{spacing(16)};
    --panel-item-size: 6rem; // 96px
  }

  @include from-breakpoint(xl) {
    --large-hero-content-width: calc(
      (100% - var(--max-container-width)) / 2 + var(--max-hero-content-width) +
        var(--horizontal-container-padding) * 4
    );
  }

  // slightly smaller buttons for certain devices
  @media (pointer: fine) {
    --target-base-height: 45px;
    --target-small-height: 40px;
    --target-tiny-height: 1.5rem;
  }
}
