@import 'app.scss';
.logoLink {
  padding: spacing(2) 0;
  color: var(--color-white);

  &:hover {
    color: var(--color-white);
  }

  .solid & {
    color: var(--color-bg-brand);
  }

  @media (pointer: pointer) {
    &:hover,
    &:focus-visible {
      color: var(--color-red-500);
    }

    &:focus-visible {
      @include generic-focus;

      outline-color: currentcolor;
    }
  }
}

.logo {
  height: 1.8125rem; // 29px (design)

  path {
    transition: 500ms;
    transition-delay: 300ms;

    &:hover {
      transition-delay: 0s;
      color: var(--color-red-300);
    }
  }
}

.zigzagCampaignHero {
  &:focus-visible {
    outline-color: var(--color-fg-zigzag-hero);
  }

  .logo {
    color: var(--color-fg-zigzag-hero);
  }
}
