@import 'app.scss';
.panel {
  --border-radius: var(--border-radius-base);
  --horizontal-padding: #{spacing(6)};
  --panel-position: 0;

  position: fixed;
  right: var(--panel-position);
  bottom: 0;
  z-index: 103;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-base);
  background-color: var(--color-bg-default);
  width: 100%;
  overflow-y: auto;

  &.inStore {
    right: 0;
    bottom: 160px;
    z-index: -1;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    max-width: 33rem;
    font-size: 1.875rem;

    .linkTel,
    .submit {
      display: none;
    }

    .content {
      padding: 30px;
      padding-bottom: 50px;
      font-size: 1.5rem;
    }

    .title {
      font-size: 3.125rem;
    }

    .subtitle {
      font-size: 2.25rem;
    }

    .fontSize,
    .fontSizeContainer {
      font-size: 1.875rem;
    }

    .sizeButton {
      width: 90px;
      height: 90px;

      &:hover,
      &:focus {
        background-color: var(--color-turquoise-400);
        color: var(--color-fg-on-secondary-cta);
      }
    }
  }

  @include from-breakpoint(md) {
    --horizontal-padding: #{spacing(5)};
    --panel-position: #{spacing(4)};

    max-width: 23.5rem;
  }
}

.content {
  @include typography-paragraph-md;

  display: grid;
  justify-items: flex-start;
  padding: spacing(6) var(--horizontal-padding);
  width: min-content;
  min-width: 100%;
  color: var(--color-fg-on-default);

  .button:not([aria-expanded='true']) ~ & {
    display: none;
  }

  @include until-breakpoint(md) {
    [aria-expanded='true'] ~ & {
      width: 100%;
    }
  }
}

.fontSizeContainer {
  display: flex;
  flex-flow: wrap;
  gap: spacing(2);
  align-items: center;
  justify-content: space-between;
  margin-bottom: spacing(6);
  border-bottom: 1px solid var(--color-border-light);
  padding-bottom: spacing(6);
  width: 100%;
}

.buttonsContainer {
  display: flex;
  gap: spacing(3);
  align-items: center;
}

.sizeButton {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  transition: background-color var(--transition-base);
  border-radius: var(--border-radius-base);
  background-color: var(--color-turquoise-400);
  padding: spacing(3);
  color: var(--color-fg-on-secondary-cta);

  &:hover,
  &:focus {
    background-color: var(--color-turquoise-200);
  }

  &:focus-visible {
    @include generic-focus;
  }

  &:disabled {
    background-color: var(--color-turquoise-200);
    color: var(--color-fg-on-default);
    pointer-events: none;
  }
}

.sizeIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.iconContainer {
  display: flex;
  flex-direction: row;
  gap: spacing(6);
  align-items: center;
  width: 100%;
}

.iconPhone {
  width: 1.2em;
  height: 1.2em;
  color: var(--color-red-500);
}

.linkTel {
  margin-top: spacing(2);
}

.fontSize {
  @include typography-paragraph-lg;

  margin-bottom: spacing(1);
}

.subtitle {
  @include typography-paragraph-lg;

  margin-bottom: spacing(6);
}

.close {
  justify-self: flex-end;
  transform: translateY(10%);
  transition: var(--transition-base);
  color: var(--color-red-500);

  &:hover,
  &:focus {
    color: var(--color-black-500);
  }

  &:focus-visible {
    @include generic-focus;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: spacing(6);
  width: 100%;
}

.title {
  margin-bottom: 0;
}

.submit {
  margin-top: spacing(6);
}
