@import 'app.scss';
.wrapper {
  --icon-size: 1.5rem;
  --icon-spacing: #{spacing(4)};

  position: relative;
  width: 100%;
}

.icon {
  position: absolute;
  top: 50%;
  left: var(--icon-spacing);
  transform: translateY(-50%);
  width: var(--icon-size);
  height: var(--icon-size);
  color: var(--color-black-500);
  pointer-events: none;
}

.input {
  @include typography-paragraph-md;

  transition: var(--transition-base);
  outline: none;
  border: 1px solid var(--color-gray-500);
  border-radius: var(--border-radius-base);
  box-shadow: var(--box-shadow-card);
  padding: spacing(3);
  width: 100%;
  min-height: var(--input-height);
  resize: none;

  &:disabled {
    background-color: var(--color-black-100);
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      border-color: var(--color-red-500);
    }
  }
}

.icon + .input {
  padding-left: calc(var(--icon-size) + var(--icon-spacing) * 2);
}

.label {
  position: absolute;
  top: calc(var(--input-height) / 2);
  left: spacing(3);
  transform: translateY(-50%);
  transition: var(--transition-base);
  cursor: text;
  padding: 0 spacing(1);
  color: var(--color-black-300);
  font-size: 1.125rem;
  font-weight: 400;

  .wrapper:focus-within &,
  .wrapper.hasValue & {
    top: 0;
    background-color: var(--color-white);
    color: var(--color-black-400);
    font-size: 1rem;
  }
}

.asterix {
  color: var(--color-red-500);
}
