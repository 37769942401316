@import 'app.scss';
.footer {
  --logo-size: 4.375rem;

  background-color: var(--color-bg-default);
  color: var(--color-fg-default);
}

.topBar {
  @include container;

  padding-top: spacing(6);
  padding-bottom: spacing(6);

  @include from-breakpoint(md) {
    --grid-spacing: #{spacing(6)};

    display: grid;
    padding-top: spacing(16);
    padding-bottom: spacing(16);
  }
}

.column {
  grid-column: 1/-1;

  &.navigationColumn {
    border-bottom: 1px solid var(--color-border-light);

    @include from-breakpoint(md) {
      border-bottom: none;
    }
  }

  &.newsletterColumn {
    grid-column: 1/-1;
    margin: spacing(4) 0 spacing(6);

    @include from-breakpoint(md) {
      grid-column: span 8;
      margin: 0;
    }

    @include from-breakpoint(lg) {
      grid-column: span 4;
    }
  }

  @include from-breakpoint(md) {
    grid-column: span 4;
  }

  @include from-breakpoint(lg) {
    grid-column: span 2;
  }
}

.title {
  @include typography-paragraph-lg;

  margin-bottom: 0;

  @include from-breakpoint(md) {
    margin-bottom: spacing(4);
  }
}

.socialsWrapper {
  display: flex;
  gap: spacing(3);
  justify-content: center;

  @include from-breakpoint(md) {
    justify-content: flex-start;
  }
}

.socialsIcon {
  color: var(--color-red-500);

  &:hover,
  &:focus {
    color: var(--color-black);
  }
}

.bottomBar {
  position: relative;

  @include from-breakpoint(md) {
    background: var(--color-gray-300);
  }
}

.bottomBarDesktopContainer {
  @include from-breakpoint(md) {
    @include container-width;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: calc(
      var(--horizontal-container-padding) + var(--logo-size)
    ); // Horizontal padding + width of icon in the footer
  }
}

.bottomLinksWrapper {
  display: flex;
  flex-wrap: wrap;
  grid-column: 1/6;
  gap: spacing(4);
  justify-content: center;
  margin-bottom: 0;

  .bottomLink:not(:last-of-type)::after {
    margin-left: spacing(4);
    content: 'x';
  }

  @include until-breakpoint(md) {
    @include container-width;

    padding-bottom: spacing(6);
  }
}

.slogan {
  grid-column: 1/6;
  margin-bottom: 0;
  background-color: var(--color-gray-300);
  padding-top: spacing(6);
  padding-bottom: spacing(6);
  overflow: hidden;

  @include until-breakpoint(md) {
    @include container-width;

    padding-right: calc(
      var(--horizontal-container-padding) + var(--logo-size)
    ); // Horizontal padding + width of icon in the footer
  }
}

.logo {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: var(--logo-size);
  height: var(--logo-size);
}

.newsletter {
  max-width: 22.5rem; // 360px
}

.socialsTitle {
  display: none;

  @include from-breakpoint(md) {
    display: block;
  }
}
