@import 'app.scss';
.container {
  margin: auto;
  border-radius: var(--border-radius-base);
  box-shadow: 0 4px 4px 0 #0000001a;
  background-color: var(--color-white);
  padding: spacing(4);
  width: 100%;
  max-width: 30rem;

  &.inStore {
    border-radius: 24px;
    padding: 65px;
    max-width: 760px;

    .title {
      font-size: 3.125rem;
    }

    .text {
      margin-top: 38px;
      font-size: 1.5rem;
    }

    .cta {
      margin-top: 48px;
    }
  }

  @include from-breakpoint(sm) {
    padding: spacing(6);
  }

  @include from-breakpoint(md) {
    padding: spacing(8);
  }

  @include from-breakpoint(md) {
    text-align: center;
  }
}

.title {
  margin-bottom: 0;
  line-height: 0.9;
}

.text {
  margin-top: spacing(4);
  margin-bottom: 0;
  line-height: 1.3;
}

.cta {
  margin-top: spacing(4);
}
