@import 'app.scss';
.container {
  --border-spacing: #{spacing(6)};
  --menu-link-size: 20rem;

  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: calc(var(--panel-item-size) + #{spacing(4)});
  height: 100%;

  @include from-breakpoint(lg) {
    --menu-link-size: 16rem;
  }

  @include from-breakpoint(xl) {
    --menu-link-size: 20rem;
  }
}

.title {
  display: flex;
  gap: spacing(4);
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  font-size: 2.5rem; // differs from default typography mixins
  font-weight: 500;
}

.close {
  transform: translateY(10%); // To align the x-mark with the menu label
  transition: var(--transition-base);
}
