@import 'app.scss';
/* stylelint-disable selector-class-pattern */

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1031;
  background: var(--color-red-500);
  width: 100%;
  height: 3px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  transform: rotate(3deg) translate(0, -4px);
  opacity: 1;
  box-shadow: 0 0 10px var(--color-red-500), 0 0 5px var(--color-red-500);
  width: 100px;
  height: 100%;
}

/* Remove these to get rid of the spinner */

/* #nprogress .spinner {
  display: 'block';
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}
#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: var(--color-red-500);
  border-left-color: var(--color-red-500);
  border-radius: 50%;
  -webkit-animation: nprogresss-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
} */
.nprogress-custom-parent {
  position: relative;
  overflow: hidden;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
