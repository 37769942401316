/* stylelint-disable selector-class-pattern */

.u-visually-hidden {
  @include visually-hidden;
}

.u-visually-hidden\@until-md {
  @include until-breakpoint(md) {
    @include visually-hidden;
  }
}

.u-visually-hidden\@from-md {
  @include from-breakpoint(md) {
    @include visually-hidden;
  }
}
