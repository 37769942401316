/* stylelint-disable selector-class-pattern */

.u-typography-paragraph-sm {
  @include typography-paragraph-sm;
}

.u-typography-paragraph-md {
  @include typography-paragraph-md;
}

.u-typography-paragraph-lg {
  @include typography-paragraph-lg;
}

.u-typography-heading-1 {
  @include typography-heading-1;
}

.u-typography-heading-2 {
  @include typography-heading-2;
}

.u-typography-heading-3 {
  @include typography-heading-3;
}

.u-typography-heading-4 {
  @include typography-heading-4;
}
