@import 'app.scss';
@keyframes pending-spinner {
  from {
    transform: translate(50%, -50%);
  }

  to {
    transform: translate(50%, -50%) rotate(1turn);
  }
}

.spinner {
  animation: pending-spinner 750ms linear infinite;
}
