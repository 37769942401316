@import 'app.scss';
@keyframes onmount-fade-in {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

.sidePanel {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 101;
  box-shadow: var(--box-shadow-card);
  background-color: var(--color-white);
  width: var(--panel-item-size);
  animation: onmount-fade-in ease 1 forwards;
  animation-duration: var(--onmount-animation-duration);

  &.inView,
  &.menuOpen {
    box-shadow: none;
  }
}

.list {
  transition: height var(--transition-duration-interaction)
    var(--transition-timing-base);
  margin-bottom: 0;
  width: 100%;
  height: 0;
  overflow: hidden;

  .inView &,
  &:focus-within {
    height: calc(3 * var(--panel-item-size));
  }

  .menuOpen & {
    height: calc(100dvh - var(--panel-item-size));
  }
}

.panelItem {
  display: flex;
  flex-direction: column;
  gap: spacing(2);
  align-items: center;
  justify-content: center;
  transition: var(--transition-base);
  outline: none;
  background-color: var(--color-white);
  width: var(--panel-item-size);
  height: var(--panel-item-size);
  line-height: 1;
  color: var(--color-fg-default);
  font-size: 0.875rem; // line-height/font-size combination differs from typography

  &:hover,
  &:focus-visible {
    @media (pointer: fine) {
      background-color: var(--color-bg-brand);
      text-decoration: none;
      color: var(--color-white);
    }
  }

  &.active {
    background-color: var(--color-bg-menu);
    color: var(--color-fg-alternative);
  }

  @include from-breakpoint(lg) {
    gap: spacing(3);
  }
}

.icon {
  transition: inherit;
  width: 1.5rem;
  height: 1.5rem;
  color: var(--color-red-500);

  .panelItem:hover &,
  .panelItem:focus-visible & {
    @media (pointer: fine) {
      color: var(--color-white);
    }
  }

  .panelItem.active & {
    color: var(--color-red-500);
  }

  .panelItem.active:focus-visible &,
  .panelItem.active:hover & {
    @media (pointer: fine) {
      color: var(--color-fg-alternative);
    }
  }
}

.iconContainer {
  position: relative;
}

.counter {
  display: flex;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-extra-small);
  background-color: var(--color-orange-500);
  padding: spacing(1);
  width: 1rem;
  height: 1rem;
  color: var(--color-white);
}
