@import 'app.scss';
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: spacing(-3);
  width: 2.5rem;
  height: 2.5rem;
  color: var(--color-red-500);

  &:hover,
  &:focus {
    color: var(--color-black-500);
  }

  &:hover {
    background-color: var(--color-black-200);
  }

  &:focus-visible {
    @include generic-focus;
  }

  @include from-breakpoint(md) {
    margin-right: spacing(-2);
  }
}

.icon {
  width: 1.5rem;
  height: 1.5rem;

  @include from-breakpoint(md) {
    width: 2rem;
    height: 2rem;
  }
}
