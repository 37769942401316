@import 'app.scss';
.container {
  --menu-link-size: 20rem;

  @include from-breakpoint(lg) {
    --menu-link-size: 16rem;
  }

  @include from-breakpoint(xl) {
    --menu-link-size: 20rem;
  }
}

.panel {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  background-color: var(--color-bg-menu);
  padding-right: var(--panel-item-size);
  width: 100%;
  max-width: 52.25rem;
  height: 100dvh;
  height: 100lvh;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  transition: var(--transition-duration-menu);
  opacity: 0;
  z-index: 100;
  background: var(--color-backdrop);
  width: 100%;
  height: 100%;
  pointer-events: none;

  &.showBackdrop {
    opacity: 1;
    pointer-events: all;
  }
}

.panelContainer {
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding-bottom: spacing(6);
  height: 100%;
  overflow-y: auto;
}

.logoLink {
  display: flex;
  position: absolute;
  align-items: center;
  z-index: 1;
  height: var(--panel-item-size);
  color: var(--color-red-500);
}

.fadeEnter {
  transform: translateX(100%);
}

.fadeEnterActive {
  transform: translateX(0);
  transition: var(--transition-duration-menu);
}

.fadeExit {
  transform: translateX(0);
}

.fadeExitActive {
  transform: translateX(100%);
  transition: var(--transition-duration-menu);
}

.searchButton {
  margin-top: auto;

  @include from-breakpoint(md) {
    max-width: 19rem;
  }
}
