@import 'app.scss';
.container {
  --border-spacing: #{spacing(6)};
  --menu-link-size: 20rem;

  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: calc(var(--panel-item-size) + #{spacing(4)});
  height: 100%;

  @include from-breakpoint(lg) {
    --menu-link-size: 16rem;
  }

  @include from-breakpoint(xl) {
    --menu-link-size: 20rem;
  }
}

.nav {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: spacing(2);
  border-top: 1px solid var(--color-border-base);
  padding-top: var(--border-spacing);

  @include from-breakpoint($menu-breakpoint) {
    border-top: none;
  }
}

.switch {
  display: flex;
  flex-direction: column;
  row-gap: spacing(4);
  margin-top: auto;
}

.list {
  display: flex;
  flex-direction: column;
}

.link {
  display: block;
  transition: var(--transition-base);
  margin: 0 spacing(-2);
  padding: spacing(2);
  max-width: var(--menu-link-size);
  line-height: 1.1;
  font-size: 1.125rem; // differs from default typography mixins
  font-weight: 400;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
    color: var(--color-red-500);
  }

  @include from-breakpoint($menu-breakpoint) {
    text-decoration: underline;
    text-underline-offset: 0.1em;

    &:hover,
    &:focus-visible {
      text-decoration: none;
    }
  }
}

.border {
  margin-bottom: var(--border-spacing);
  border-bottom: 1px solid var(--color-border-base);
  padding-bottom: var(--border-spacing);

  @include from-breakpoint($menu-breakpoint) {
    border-bottom: none;
  }
}

.accessibility {
  justify-content: start;
  background: var(--color-light-gray-500);
  max-width: 19rem;

  &:hover {
    border-color: var(--color-black-300);
    background-color: initial;
  }
}
