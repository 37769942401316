@import 'app.scss';
.tag {
  padding: spacing(2) spacing(3);
  line-height: 1;
  font-size: 1rem;

  &.md {
    border-radius: var(--border-radius-small);
    background: var(--color-black-500);
    color: var(--color-white);
  }

  &.sm {
    border-radius: var(--border-radius-small);
    background-color: var(--color-black-500);
    color: var(--color-white);
    font-weight: 500;
  }
}
