@import 'app.scss';
.list {
  --spacing: #{spacing(6)};
  --link-width: 15.75rem;

  display: none;
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: 0;
  background-color: var(--color-bg-menu);
  width: 100%;
  height: 100%;
  overflow-y: auto;
  color: var(--color-fg-on-surface);

  &.subMenuIsShown {
    display: block;
  }

  @include from-breakpoint($menu-breakpoint) {
    --margin-top: calc(#{spacing(16)} + var(--spacing));

    position: absolute;
    top: 0;
    left: calc(
      var(--menu-link-size) + var(--horizontal-container-padding) + spacing(12)
    );
    margin-top: var(--margin-top);
    width: calc(
      100% - var(--menu-link-size) - var(--horizontal-container-padding) -
        spacing(12)
    );
    height: calc(100% - var(--margin-top));
  }
}

.nestedList {
  @include from-breakpoint($menu-breakpoint) {
    left: calc(
      var(--menu-link-size) + var(--horizontal-container-padding) + spacing(12)
    );
    padding: calc(spacing(16) + var(--spacing)) 0 var(--spacing);
    width: var(--menu-link-size);
  }
}

.title {
  display: none;
}

.backItem {
  margin: 0 calc(-1 * var(--horizontal-container-padding)) spacing(4);
  box-shadow: var(--box-shadow-base);
  padding: 0 var(--horizontal-container-padding);

  @include from-breakpoint($menu-breakpoint) {
    display: none;
  }
}

.backButton {
  justify-content: flex-start;
  padding-bottom: spacing(4);
  font-weight: normal;

  @include from-breakpoint($menu-breakpoint) {
    display: none;
  }
}

.subMenuLink {
  line-height: 2.1;

  @include from-breakpoint($menu-breakpoint) {
    &[aria-expanded='true'] {
      text-decoration: none;
      color: var(--color-red-500);

      .toggleIcon {
        color: var(--color-black-500);
      }
    }
  }
}

.topSubMenuLink {
  display: block;
}

.nestedSubMenuLink {
  border-bottom: 1px solid var(--color-border-light);
  line-height: 2.75rem; // custom line-height to match the height of the first menu-items
  font-size: 1.125rem;

  @include from-breakpoint($menu-breakpoint) {
    border-bottom: none;
    line-height: 2.1;
    font-size: 1.375rem;
  }
}

.toggleIcon,
.backIcon {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--color-red-500);
}
