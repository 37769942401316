@import 'app.scss';
.saveButton {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: var(--transition-duration-menu);
  border-radius: 50%;
  background-color: var(--bg-color);
  width: var(--input-height);
  height: var(--input-height);
  color: var(--fg-color);
  backdrop-filter: blur(12px);

  &.saved {
    background-color: var(--bg-hover-color);
    color: var(--fg-hover-color);
  }

  &.gray {
    --bg-color: rgb(0 0 0 / 0.24);
    --bg-hover-color: rgb(255 255 255 / 0.24);
    --fg-color: var(--color-white);
    --fg-hover-color: var(--color-white);
    --check-color: var(--color-black);
  }

  &.brand {
    --bg-color: var(--color-white);
    --bg-hover-color: var(--color-bg-brand);
    --fg-color: var(--color-bg-brand);
    --fg-hover-color: var(--color-white);
    --check-color: var(--color-bg-brand);

    /* border & shadow same as white button styling */
    border: solid 1px var(--color-border-default);
    box-shadow: var(--box-shadow-card);
  }

  &.iconOnly {
    --check-color: var(--color-white);

    background-color: transparent;
    color: var(--color-bg-brand);
  }

  @media (pointer: fine) {
    &:focus-visible {
      @include generic-focus;

      outline-color: var(--color-white);
      background-color: var(--color-bg-brand-hover);
      color: var(--color-fg-brand-hover);
    }

    &:hover {
      background-color: var(--bg-hover-color);
      color: var(--fg-hover-color);
    }

    &.saved:focus-visible {
      --check-color: var(--color-white);
      --bg-color: var(--color-bg-brand);

      background-color: var(--color-bg-alternative);
      color: var(--color-bg-brand-hover);
    }
  }
}

.iconContainer {
  position: relative;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.check {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%) scale(0.5);
  transition: inherit;
  opacity: 0;
  width: 0.75rem;
  height: 0.75rem;
  color: var(--check-color);

  .saved & {
    transform: translate(-50%, -60%) scale(1);
    opacity: 0.8;
  }
}

.saveButton.inStore {
  border-radius: var(--border-radius-base);
  padding: 36px 48px;
  width: unset;
  height: unset;
  line-height: 0.6667;
  color: var(--color-fg-default);
  font-size: 1.875rem;

  .iconContainer {
    margin-right: 20px;
  }

  .icon {
    color: var(--fg-color);
  }

  &.saved {
    color: var(--fg-hover-color);

    .icon {
      color: var(--fg-hover-color);
    }
  }
}

.iconOnly {
  backdrop-filter: none;
}
