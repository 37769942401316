/* stylelint-disable selector-class-pattern */

.u-flex {
  display: flex;
}

.u-flex--wrap {
  flex-wrap: wrap;
}

.u-flex--gap-4 {
  gap: spacing(4);
}
