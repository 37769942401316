/*  =========================================================================
    Token
    Global token variables
    You can NOT import this file other .scss files, instead it is imported in _app.js
    ========================================================================= */
:root,
::marker,
::after,
::before {
  /* Background colors,
    use backgrounds to give elements a background color to provide contrast,
    to differentiate importancy or to convey meaning
  */

  --color-bg-menu: var(--color-gray-300);
  --color-bg-body: var(--color-gray-100);
  --color-bg-default: var(--color-white);
  --color-bg-alternative: var(--color-gray-100);
  --color-bg-brand: var(--color-red-500);
  --color-bg-brand-hover: var(--color-red-400);
  --color-bg-primary: var(--color-purple-500);
  --color-bg-primary-hover: var(--color-purple-400);
  --color-bg-secondary: var(--color-orange-500);
  --color-bg-secondary-hover: var(--color-orange-400);
  --color-bg-disabled: var(--color-black-200);
  --color-bg-zigzag-hero: var(--color-black-500);

  /* Foreground colors,
    Use foregrounds to color text and icons.
    Most foregrounds should be paired with their respective bg.{name} equivalent.
  */
  --color-fg-body: var(--color-black-500);
  --color-fg-default: var(--color-black-500);
  --color-fg-alternative: var(--color-black-500);
  --color-fg-brand: var(--color-white);
  --color-fg-brand-hover: var(--color-fg-brand);
  --color-fg-primary: var(--color-white);
  --color-fg-primary-hover: var(--color-fg-primary);
  --color-fg-secondary: var(--color-black-500);
  --color-fg-secondary-hover: var(--color-fg-secondary);
  --color-fg-disabled: var(--color-gray-900);
  --color-fg-zigzag-hero: var(--color-white);
  --color-fg-marker: currentcolor;

  /* Border colors,
    Borders are used to outline UI elements like form inputs and buttons, or to separate data in list views and tables.
    Most borders should be paired with their respective bg.{name} and sometimes their fg.{name} equivalent.
  */
  --color-border-default: var(--color-gray-500);
  --color-border-interactive: var(--color-purple-500);

  /* Shadows & outlines */
  --shadow-tile: var(--box-shadow-base);
  --shadow-tile-alternative: var(--box-shadow-alternative);
}

/* stylelint-disable-next-line selector-class-pattern */
.palette-1 {
  --color-bg-primary: var(--color-steel-500);
  --color-bg-primary-hover: var(--color-steel-400);
  --color-bg-primary-disabled: var(--color-steel-200);
  --color-bg-secondary: var(--color-green-500);
  --color-bg-secondary-hover: var(--color-green-400);
  --color-bg-secondary-disabled: var(--color-green-200);
  --color-fg-primary: var(--color-white);
  --color-fg-secondary: var(--color-black-500);
}

/* stylelint-disable-next-line selector-class-pattern */
.palette-2 {
  --color-bg-primary: var(--color-sand-500);
  --color-bg-primary-hover: var(--color-sand-400);
  --color-bg-primary-disabled: var(--color-sand-200);
  --color-bg-secondary: var(--color-yellow-500);
  --color-bg-secondary-hover: var(--color-yellow-400);
  --color-bg-secondary-disabled: var(--color-yellow-200);
  --color-fg-primary: var(--color-black-500);
  --color-fg-secondary: var(--color-black-500);
}

/* stylelint-disable-next-line selector-class-pattern */
.palette-3 {
  --color-bg-primary: var(--color-indigo-500);
  --color-bg-primary-hover: var(--color-indigo-400);
  --color-bg-primary-disabled: var(--color-indigo-200);
  --color-bg-secondary: var(--color-turquoise-500);
  --color-bg-secondary-hover: var(--color-turquoise-400);
  --color-bg-secondary-disabled: var(--color-turquoise-200);
  --color-fg-primary: var(--color-white);
  --color-fg-secondary: var(--color-black-500);
}

/* stylelint-disable-next-line selector-class-pattern */
.palette-4 {
  --color-bg-primary: var(--color-royal-500);
  --color-bg-primary-hover: var(--color-royal-400);
  --color-bg-primary-disabled: var(--color-royal-200);
  --color-bg-secondary: var(--color-gold-500);
  --color-bg-secondary-hover: var(--color-gold-400);
  --color-bg-secondary-disabled: var(--color-gold-200);
  --color-fg-primary: var(--color-white);
  --color-fg-secondary: var(--color-black-500);
}
