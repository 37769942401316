@import 'app.scss';
@keyframes onmount-fade-in {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0%);
  }
}

.header {
  width: 100%;

  &.hasHero:not(.hasHeroWithoutImage),
  &.isCampaignPage {
    height: 0;
  }
}

.topBar {
  position: relative;
  z-index: 100;
  width: 100%;
  animation: onmount-fade-in ease 1 forwards;
  animation-duration: var(--onmount-animation-duration);

  @include from-breakpoint(lg) {
    &::before {
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      background: linear-gradient(180deg, rgb(0 0 0 / 0.8), rgb(0 0 0 / 0));
      width: 100%;
      height: 100%;
      content: '';

      .hasLargeHero & {
        width: calc(100% - var(--large-hero-content-width));
      }

      .solid & {
        background: var(--color-bg-body);
      }
    }
  }
}

.inViewRef {
  position: absolute;
  top: 50%;
  left: 0;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--panel-item-size);

  @include until-breakpoint(xl) {
    @include container-width;

    padding-right: calc(var(--panel-item-size) + spacing(12));
  }

  @include from-breakpoint(xl) {
    // This padding makes sure that menu-items will not get behind side-panel
    padding-right: max(
      var(--horizontal-container-padding),
      (var(--max-container-width) - 100vw) / 2 + var(--panel-item-size) * 2
    );
  }
}

.list {
  display: none;
  gap: spacing(8);
  align-items: stretch;
  justify-content: flex-end;
  margin-bottom: 0;

  @include from-breakpoint(lg) {
    display: flex;
  }
}

.link {
  display: flex;
  align-items: center;
  height: 100%;
  color: var(--color-white);

  &:hover,
  &:focus {
    color: var(--color-white);
  }

  &:focus-visible {
    @include generic-focus;

    outline-color: currentcolor;
    text-decoration: none;
  }

  .solid & {
    color: var(--color-black);
  }
}

.isCampaignPage {
  .topBar {
    @include from-breakpoint(md) {
      &::before {
        display: none;
      }
    }
  }
}
