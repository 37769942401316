@import 'app.scss';
.card {
  --card-transition: var(--transition-duration-interaction)
    var(--transition-timing-base);
  --padding: #{spacing(4)};
  --line-clamp: 2;

  position: relative;
  transition: var(--card-transition);
  border-radius: var(--border-radius-base);
  box-shadow: var(--box-shadow-card);
  background-color: var(--color-bg-default);
  width: 100%;
  height: 100%;
  color: var(--color-fg-default);
  font-family: var(
    --font-family-base,
    'Calibre, sans-serif'
  ); // variable not working within leaflet embed

  /* Show card animation only on desktop devices */
  @media (pointer: fine) {
    &:hover {
      transform: translateY(-0.5rem);

      .image,
      .placeholder {
        transform: scale(1.1);
      }
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-base);
  overflow: hidden;
}

.imgContainer {
  position: relative;
  order: -1;
  overflow: hidden;

  &.xs {
    height: 6.5rem;
  }

  &.sm {
    height: 9.875rem;
  }

  &.md {
    height: 12.5rem;
  }

  &.lg {
    height: 15rem;
  }

  &.xl {
    height: 19.5rem;
  }

  &.md\@from-md {
    @include from-breakpoint(md) {
      height: 12.5rem;
    }
  }

  &.lg\@from-lg {
    @include from-breakpoint(lg) {
      height: 19.5rem;
    }
  }
}

.image {
  object-fit: cover;
  transition: var(--card-transition);
}

.tag {
  position: absolute;
  bottom: var(--padding);
  left: var(--padding);
}

.save {
  position: absolute;
  top: var(--padding);
  right: var(--padding);
  z-index: 1;
}

.content {
  display: flex;
  flex-flow: column nowrap;
  padding: var(--padding);
}

.link {
  display: flex;
  gap: spacing(1);
  align-items: center;
  text-decoration: none;

  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

.title {
  @include typography-paragraph-md;

  display: -webkit-box;
  margin-bottom: 0;
  overflow: hidden;
  font-weight: 500;
  -webkit-line-clamp: var(--line-clamp); /* number of lines to show */
  line-clamp: var(--line-clamp);
  -webkit-box-orient: vertical;

  @include from-breakpoint(md) {
    @include typography-paragraph-lg;
  }
}

.subtitle {
  @include typography-paragraph-sm;

  order: -1;
  margin: 0 0 spacing(2) !important; // to overwrite leaflet styles
  color: var(--color-black-400);
}

.chevron {
  flex-shrink: 0;
  transform: translateY(10%);
  width: 1rem;
  height: 1rem;
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--card-transition);
  background-color: var(--color-black-400);
  width: 100%;
  height: 100%;
  color: var(--color-black-100);
}

.card.isPopup,
.card.noTransform {
  @media (pointer: fine) {
    &:hover {
      transform: translateY(0);
    }
  }
}

.card.isPopup {
  width: 100%;
}

.card.inStore {
  --padding: 24px 30px;
  --line-clamp: 3;

  &:hover,
  &:focus {
    transform: none;

    .link {
      text-decoration: none;
    }

    .image {
      transform: none;
    }
  }

  .save,
  .tag {
    --padding: 24px;
  }

  .subtitle {
    line-height: 1.28;
    font-size: 1.25rem;
  }

  .title {
    font-size: 1.875rem;
  }
}

.card.flat {
  flex-direction: row;

  .imgContainer {
    flex-shrink: 0;
    width: 140px;
    height: auto;
    min-height: 150px;
  }

  .link {
    font-weight: 500;
  }

  .save,
  .tag {
    display: none;
  }

  .fakeButton {
    display: inline-flex;
    gap: 10px;
    align-items: center;
    margin-top: 16px;
    border-radius: 12px;
    box-shadow: 0 2px 2px 0 #0000001a;
    background-color: var(--color-purple-500);
    padding: 14px 16px;
    width: fit-content;
    line-height: 1;
    color: var(--color-white);
    font-size: 1.25rem;
    font-weight: 500;
  }
}

.highlightedCorner {
  display: flex;
  position: absolute;
  top: -4px;
  left: -4px;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.cornerIcon {
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 201 !important; // The important is neede because of the leaflet styles
  width: spacing(5);
  height: spacing(5);
}

/* stylelint-disable-next-line selector-class-pattern */
.card.BREDA_STRAALT {
  .cornerIcon {
    color: var(--color-white);
  }

  .corner {
    color: var(--color-breda-straalt);
  }

  .tag {
    background-color: var(--color-breda-straalt);
    color: var(--color-white);
  }
}
