@import 'app.scss';
.button {
  @include shared-input;
  @include typography-paragraph-md;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-base);
  background-color: var(--bg-color);
  min-height: var(--input-height);
  text-decoration: none;
  color: var(--color);
  font-family: var(--font-family-base);

  .iconContainer {
    color: var(--icon-color, inherit);
  }

  @media (pointer: fine) {
    &:hover,
    &:focus-visible {
      transition-duration: var(--transition-duration-interaction);
      transition-timing-function: cubic-bezier(0.25, 3, 0.62, 0.34);
      background-color: var(--bg-color-hover);
      text-decoration: none;
      color: var(--color-hover);

      .iconContainer {
        color: var(--icon-color-hover, var(--icon-color, inherit));
      }

      .iconContainerRight {
        transform: translateX(4px);
        transition-timing-function: cubic-bezier(0.25, 3, 0.62, 0.34);
      }
    }
  }
}

.inner {
  display: flex;
  position: relative;
  align-items: center;
  pointer-events: none;
}

.label {
  display: flex;
  font-weight: 500;

  @supports (-webkit-touch-callout: none) {
    // Safari mobile only
    margin-top: spacing(-1);
  }
}

.count {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  transform: translate(25%, -50%);
  border: solid 1px var(--color-border-default);
  border-radius: 50%;
  box-shadow: var(--box-shadow-card);
  background-color: var(--color-bg-default);
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1;
  color: var(--color-fg-default);
  font-size: 0.875rem;
  font-weight: 500;
}

.iconContainer {
  transition: transform var(--transition-duration-interaction);
  transition-timing-function: ease;
  margin-left: spacing(3);
  text-decoration: none;

  &:first-child {
    margin-right: spacing(3);
    margin-left: 0;
  }
}

.iconContainerLeft {
  .svgIcon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.iconContainerRight {
  .svgIcon {
    width: 1rem;
    height: 1rem;
  }
}

.button.small {
  --shared-input-padding-vertical: 0.75rem;
}

.button.hideLabel {
  justify-content: center;
  box-shadow: none;
  width: var(--input-height);
  height: var(--input-height);

  .label {
    @include visually-hidden;
  }

  .iconContainer {
    margin: 0;
  }

  .iconContainerRight {
    .svgIcon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.button.hideLabel,
.button.noAnimation {
  &:hover,
  &:focus-visible {
    .iconContainer {
      transform: none;
    }
  }
}

.button.primary {
  --color: var(--color-fg-primary);
  --color-hover: var(--color-fg-primary-hover);
  --bg-color: var(--color-bg-primary);
  --bg-color-hover: var(--color-bg-primary-hover);
}

.button.brand {
  --color: var(--color-fg-brand);
  --color-hover: var(--color-fg-brand-hover);
  --bg-color: var(--color-bg-brand);
  --bg-color-hover: var(--color-bg-brand-hover);
}

.button.secondary {
  --color: var(--color-fg-default);
  --color-hover: var(--color-fg-default-hover);
  --bg-color: var(--color-turquoise-500);
  --bg-color-hover: var(--color-turquoise-400);
}

.button.white {
  --color: var(--color-fg-default);
  --color-hover: var(--color-fg-default);
  --bg-color: var(--color-bg-default);
  --bg-color-hover: var(--color-bg-default);
  --icon-color: var(--color-red-500);

  border: solid 1px var(--color-border-default);
  box-shadow: var(--box-shadow-card);

  &:hover,
  &:focus-visible {
    box-shadow: none;
  }

  &.hideLabel {
    --bg-color-hover: var(--color-red-400);
    --icon-color-hover: var(--color-fg-primary);

    outline: none;
  }
}

.button.loading {
  padding-right: calc(var(--shared-input-padding-vertical) + spacing(8));

  .loader {
    position: absolute;
    top: spacing(5);
    right: spacing(6);
    bottom: 0;
    margin: auto;
  }
}

.button.text,
.button.textLink,
.button.segmentation {
  --color: var(--color-fg-default);
  --color-hover: var(--color-fg-default);

  border: none;
  box-shadow: none;
  text-decoration: underline;
  font-weight: 500;

  &:hover,
  &:focus-visible {
    box-shadow: none;
    text-decoration: none;
  }

  &.hideLabel {
    --bg-color-hover: var(--color-red-400);
    --icon-color-hover: var(--color-fg-primary);

    outline: none;
  }
}

.button.text {
  padding-right: 0;
  padding-left: 0;
}

.button.textLink {
  --color: var(--color-bg-primary);
  --color-hover: var(--color-bg-primary);

  padding: spacing(1) 0;
  min-height: 0;
}

.button.segmentation {
  padding-right: spacing(3);
  padding-left: spacing(3);
  text-decoration: none;
  white-space: nowrap;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }
}

/* stylelint-disable-next-line no-duplicate-selectors */
.button {
  &[disabled],
  [disabled] > & {
    --color: var(--color-fg-disabled);
    --color-hover: var(--color-fg-disabled);
    --bg-color: var(--color-bg-disabled);
    --bg-color-hover: var(--color-bg-disabled);
    --icon-color: var(--color-fg-disabled);

    cursor: not-allowed;
    text-decoration: none;

    .iconContainerRight {
      transform: none;
    }
  }
}

.button.fullWidth {
  width: 100%;

  .inner {
    width: 100%;
  }

  .iconContainerRight {
    margin-left: auto;
  }
}

.button.rounded {
  border-radius: 50%;
}

.button.filterActive {
  --color: var(--color-fg-brand);
  --color-hover: var(--color-fg-brand-hover);
  --bg-color: var(--color-bg-brand);
  --bg-color-hover: var(--color-bg-brand-hover);
  --icon-color: var(--color-fg-brand);

  .iconContainer {
    display: none;

    @include from-breakpoint(lg) {
      display: block;
    }
  }

  .iconContainer.iconContainerLeft {
    display: block;
  }
}

.button.redIcon {
  --icon-color: var(--color-red-500);
}

.button.inStore {
  border-radius: 24px;
  padding: 36px 48px;
  line-height: 0.6667;
  font-size: 1.875rem;
  font-weight: 500;

  &:focus,
  &:hover {
    outline: none;
    background-color: var(--bg-color);
    text-decoration: none;
  }

  .iconContainerLeft {
    position: relative;
    width: 2.5rem;

    .svgIcon {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-50%);
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .iconContainerRight {
    position: relative;
    width: 2rem;

    .svgIcon {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-50%);
      width: 2rem;
      height: 2rem;
    }
  }

  &.white {
    box-shadow: 0 4px 4px 0 #0000001a;

    &:focus,
    &:hover {
      box-shadow: 0 4px 4px 0 #0000001a;
    }
  }

  &.small {
    border-radius: 12px;
    padding: 24px 36px;
  }
}

/* stylelint-disable-next-line selector-class-pattern */
.button.BREDA_STRAALT {
  --color: var(--color-fg-default);
  --color-hover: var(--color-fg-default);
  --bg-color: var(--color-white);
  --bg-color-hover: var(--color-white);
  --icon-color: var(--color-fg-default);

  border: solid 1px var(--color-breda-straalt);
  box-shadow: var(--box-shadow-card);

  &:hover,
  &:focus-visible {
    border: solid 1px var(--color-breda-straalt-hover);
    box-shadow: none;
  }

  &.hideLabel {
    --bg-color-hover: var(--color-breda-straalt-hover);
    --icon-color-hover: var(--color-white);

    outline: none;
  }

  &.filterActive {
    --color: var(--color-white);
    --color-hover: var(--color-white);
    --bg-color: var(--color-breda-straalt);
    --bg-color-hover: var(--color-breda-straalt-hover);
    --icon-color: var(--color-white);

    border: solid 1px var(--color-breda-straalt);
  }
}
