@import 'app.scss';
.title {
  @include typography-paragraph-lg;
}

.text {
  margin: spacing(4) 0;
}

.form {
  --icon-size: 1.5rem;
  --icon-spacing: #{spacing(4)};
}

.inputContainer {
  position: relative;
}

.icon {
  transition-duration: var(--transition-duration-menu);
  width: var(--icon-size);
  height: var(--icon-size);
}

.iconCheck {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transform-origin: 50% 50%;
  opacity: 0;
}

.submit {
  position: absolute;
  top: 0;
  right: 0;
  transition-duration: var(--transition-duration-menu);
  border-radius: var(--border-radius-base);
  background-color: var(--color-black-500);
  padding: 0 spacing(3);
  height: 100%;
  overflow: hidden;
  color: var(--color-white);

  &:not(:disabled) {
    &:hover,
    &:focus-visible {
      background-color: var(--color-red-500);
      color: var(--color-white);

      .iconDiagonal {
        transform: translate(2px, -2px);
      }
    }
  }

  &:disabled {
    background-color: var(--color-red-400);
    cursor: default;

    .iconDiagonal {
      transform: translate(-2px, 2px);
    }
  }

  .submitted & {
    transition-delay: var(--transition-duration-menu);
    background-color: var(--color-succes-500);

    .iconDiagonal {
      transform: translate(1.75rem, -1.75rem);
    }

    .iconCheck {
      transform: translate(-50%, -50%) scale(1);
      transition-delay: var(--transition-duration-menu);
      opacity: 1;
    }
  }
}

.input {
  box-shadow: var(--box-shadow-card);
  padding-right: calc(var(--icon-size) + spacing(3) * 2 + var(--icon-spacing));
}

.errorMessage {
  @include typography-paragraph-lg;

  margin-top: 0.125rem;
  margin-bottom: 0;
  border: none;
  border-radius: 4px;
  width: fit-content;
  font-style: italic;
}
