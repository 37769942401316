/*  =========================================================================
    Elements
    Unclassed HTML element selectors
    ========================================================================= */
html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  background: var(--color-bg-body);
  min-height: 100%;
  text-rendering: geometricprecision;
}

/* stylelint-disable-next-line selector-id-pattern */
#__next {
  flex: 1;
}

button {
  cursor: pointer;
}

img {
  display: block;

  // Makes sure each lazyload 3px by 3px is as big as its container
  width: 100%;
  height: auto;
}

label,
legend {
  display: block;

  small {
    font-weight: normal;
  }
}

label {
  cursor: pointer; /* [1] */
  font-weight: 600;
}

legend {
  font-weight: 500;
}

a {
  transition: color var(--transition-duration-base)
    var(--transition-timing-base);
  text-decoration: none;
  color: var(--color-text-base);
  text-decoration-skip-ink: auto;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
    color: var(--color-text-base);
  }

  &:focus-visible {
    @include generic-focus;
  }
}

strong {
  font-weight: 500;
}

hr {
  border: none;
  border-bottom: 1px solid var(--color-gray-500);
}

svg {
  display: block;
}
